<script setup lang="ts">
import { ref as yref, object, string, type InferType } from 'yup'
import type { FormSubmitEvent } from '#ui/types'
const { $hyc } = useNuxtApp()
const loginForm = useState('loginForm')
const toLoginForm = () => {
  loginForm.value = 'login'
}
const successMessage = ref('')
const errorDetails = useErrorDetails() //传递报错消息
const form = useState('registerForm')
type Schema = InferType<typeof schema>

const signupSubmit = async (event: FormSubmitEvent<Schema>) => {
  loading.value = true
  try {
    const csrf = await $hyc('/token/csrf-token')
    const body = {
      ...event.data,
      _token: csrf.data.token,
    }
    delete body['confirm_password']
    const register = await $hyc('/api/v1/user/register', {
      method: 'POST',
      body,
    })
    console.log('register', register)
    successMessage.value = '注册成功'
    setTimeout(() => {
      successMessage.value = ''
      toLoginForm()
    }, 2000)
  } catch (e) {
    console.log('err', e)
    if (errorDetails.value) {
      const entries = Object.entries(errorDetails.value) as [
        string,
        Array<string>,
      ][]
      form.value.setErrors(
        entries.map((err) => ({
          // Map validation errors to { path: string, message: string }
          message: err[1].toString().replace(',', '\n'),
          path: err[0],
        })),
      )
      errorDetails.value = null
    }
  }
  loading.value = false
}

const signupf = reactive({
  username: undefined,
  password: undefined,
  confirm_password: undefined,
  email: undefined,
  // gender: undefined, //female ,male
  // country: undefined,
})
const schema = object({
  username: string()
    .min(3, 'Must be at least 3 characters')
    .max(24, 'Must be less than 24 characters')
    .matches(/^[\u4e00-\u9fa5a-zA-Z0-9]+$/, 'Has invalid character')
    .required('Required'),
  password: string()
    .min(8, 'Must be at least 8 characters')
    .max(40, 'Must be less than 40 characters')
    .matches(/[a-z]/, 'need lowercase character')
    .matches(/[A-Z]/, 'need uppercase character')
    .matches(/[0-9]/, 'need number')
    .matches(/[()`!@#$%^&*_\-+=|{}[\]:;'<>,.?]/, 'need special character')
    .required('Required'),
  confirm_password: string()
    .oneOf([yref('password')], 'Passwords must match')
    .required('Need confirm'),
  email: string().email('Must be Email').required('Required'),
})

const loading = ref(false)
const errorMessage = useErrorMessage()
</script>
<template>
  <UForm
    ref="form"
    :schema="schema"
    :state="signupf"
    class="mx-2"
    @submit="signupSubmit"
  >
    <UFormGroup label="Username" name="username" size="xs" required>
      <UInput
        v-model="signupf.username"
        placeholder="Username"
        class="my-2 w-full"
      />
    </UFormGroup>
    <UFormGroup label="Password" name="password" size="xs" required>
      <UInput
        v-model="signupf.password"
        placeholder="Password"
        type="password"
        class="my-2 w-full"
      />
    </UFormGroup>
    <UFormGroup
      label="Confirm password"
      name="confirm_password"
      size="xs"
      required
    >
      <UInput
        v-model="signupf.confirm_password"
        placeholder="Confirm Password"
        type="password"
        class="my-2 w-full"
      />
    </UFormGroup>
    <UFormGroup label="Email" name="email" size="xs" required>
      <UInput
        v-model="signupf.email"
        placeholder="Email"
        type="email"
        class="my-2 w-full"
      />
    </UFormGroup>
    <UButton
      block
      :class="[
        loading ? 'animate-bounce cursor-wait' : '',
        errorMessage
          ? 'bg-red-500 hover:bg-red-500'
          : successMessage
            ? 'bg-green-500 hover:bg-green-500'
            : '',
        'p-3 transition-all',
      ]"
      type="submit"
      :label="errorMessage || successMessage || 'Signup'"
    />
    <div class="my-3 flex items-center justify-center">
      <UButton color="gray" variant="link" @click="toLoginForm">Login</UButton>
      <span class="px-4 text-gray-400">|</span>
      <UButton color="gray" variant="link">resend email</UButton>
    </div>
  </UForm>
</template>
